import React, { useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';

import {
  Alert,
  Card,
  Input,
  Checkbox,
  Button,
  Typography,
} from "@material-tailwind/react";



import AgroTechnologyLogo from './agro-small.jpg';
import { FolderMinusIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline';

function Login() {

  const navigate = useNavigate();
  const [Login, setLogin] = useState('');
  const [Password, setPassword] = useState('');
  const [authenticated, setAuthenticated] = useState(localStorage.getItem("authenticated") || false);
  const [firstname, setFirstname] = useState(localStorage.getItem("firstname") || null);
  const [session, setSession] = useState(localStorage.getItem("session") || null);
  const [userid, setUserId] = useState(localStorage.getItem("userid") || 0);
  const [rank, setRank] = useState(localStorage.getItem("rank") || 0);

  const [alert, setAlert] = useState(false);
  const [message, setMessage] = useState('');
  const [alertColor, setAlertColor] = useState('red');


  function handleLogin(data) {

    localStorage.removeItem('clients');
    localStorage.removeItem('fields');
    localStorage.removeItem('files');
    localStorage.removeItem('documents');
    localStorage.removeItem('vehicles');
    localStorage.removeItem('featureCollection');

    console.log(data)
    if (data.status === "ok") {
      setSession(data.sid);
      localStorage.setItem("session", data.sid);

      setAuthenticated(true);
      localStorage.setItem("authenticated", true);

      setFirstname(data.firstname);
      localStorage.setItem("firstname", data.firstname);

      setUserId(data.uid);
      localStorage.setItem("userid", data.uid);

      setRank(data.rank);
      localStorage.setItem("rank", data.rank);


      if (data.rankname === "admin") navigate('/manage')
      else if (data.rankname === "technik") navigate('/workflow')
      else if (data.rankname === "klient") navigate('/map')
    }
    if (data.status !== "ok") {
      setMessage(data.message);
      setAlert(true);
      const timer = setTimeout(() => {
        setAlert(false);
      }, 4000);
      return () => clearTimeout(timer);
    }

  }



  function handleSubmit(e) {
    e.preventDefault();
    var bodydata = JSON.stringify({ action: 'login', login: Login, password: Password });
    console.log(bodydata);
    fetch('https://agro-online.pl/cgi-bin/user.py', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: bodydata,
      mode: 'cors'

    })
      .then(res => res.json())
      .then(data => handleLogin(data))
  }

  return (
    <>
      <div className="flex items-center justify-center h-screen bg-gradient-to-br from-teal-100 to-blue-100">
        <div className="p-1 bg-gradient-to-br from-teal-200 to-blue-200 rounded-2xl">
          <Card color="white" className="p-6 border-cyan-500 border-0" shadow={false}>
            <img src={AgroTechnologyLogo} alt="Agro-Technology" className="object-scale-down w-32 mx-auto mb-2" />
            <Typography color="gray" className="mt-1 font-normal mx-auto">
              Wprowadź dane do logowania.
            </Typography>
            <form className="mt-4 mb-2 w-64 max-w-screen-lg sm:w-80" onSubmit={handleSubmit}>
              <div className="mb-6 flex flex-col gap-6">
                <Input size="lg" label="Email / Login" className="shadow-md" onChange={e => setLogin(e.target.value)} />
                <Input size="lg" type="password" label="Hasło" className="shadow-md" onChange={e => setPassword(e.target.value)} />
              </div>
              {/*
              <Checkbox
                label={
                  (
                    <Typography variant="small" color="gray" className="flex items-center font-normal">
                      Zapamiętaj mnie
                      <a href="terms" className="font-medium transition-colors hover:text-blue-500">&nbsp;Terms and Conditions</a>
                    </Typography>
                  )
                }
                containerProps={{ className: "-ml-2.5" }}
               />
              */}
              <div>
                <Button className="flex items-center mt-8 justify-center bg-gradient-to-br from-teal-500 to-cyan-500" fullWidth type="submit">
                  Zaloguj
                </Button>
              </div>

              <Typography color="gray" className="mt-4 text-center font-normal">Zapomniałeś
                <a href="#" className="font-small text-blue-500 transition-colors hover:text-blue-700"> hasła?</a>
              </Typography>
            </form>
            <Typography className="relative top-5 mt-4 mb-0 text-right font-normal text-xs mr-0 ml-auto text-slate-200"> v0.7.10x</Typography>
          </Card>
        </div>
      </div>
      <Alert open={alert}
        color={alertColor}
        onClose={() => setAlert(false)}
        variant="gradient" className='absolute top-8 right-8 w-96 shadow-lg'
        animate={{ mount: { y: 0 }, unmount: { y: 100 }, }}>
        <span className='inline-flex'><ExclamationTriangleIcon className='w-6 mr-2 mt-0.5' />{message}</span>
      </Alert>

    </>
  );
}

export default Login;